
declare global {
    interface Window {
        dataLayer:any;
        gtag: any;
    }
}
export const GTM_ID = process.env.GOOGLE_TAG_MANAGER_ID

export const pageview = (url) => {
 try {
    const gaDataLayer = window.dataLayer;
    gaDataLayer.push({
        event: 'pageview',
        page: url,
      })
 } catch(e) {
    console.error(e)
 }
}

export const addCustomEvent = (eventName) => {
    try {
        window.gtag('event', eventName, 'clicked');
    } catch (e) {
        console.error(e)
    }
}